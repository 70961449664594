import React from "react"
import styles from "../components/top.module.css"
import { Link } from "gatsby"

export default function Top() {
  return (
    <top>
      <div className={styles.header_contactBox}>
        <p className={styles.header_contact}>
          <Link to="/contact/">Contact</Link>
        </p>
      </div>
      <div className={styles.layout}>
        <div className={styles.firstBox}>
          <div className={styles.child_of_firstBox1}>
            <div className={styles.description}>
              <h2>新しい治療体験へ</h2>
              <div className={styles.details}>
                <p>xCuraは、痛みや不安を我慢するだけだった</p>
                <p>医療体験にイノベーションを起こし、</p>
                <p>XRによる新しい医療体験を<br />提供致します</p>
                
              </div>
            </div>
          </div>
        </div>
        <div className={styles.xrTherapyBox}>
          <h2>セラピアVR</h2>
          <p>
            xCuraは『テクノロジーによる、痛み・不安の軽減』という理念をもとに2021年に設立した会社です。
　　　　　　       </p>
          <p>
            患者様にVRを装着しながら治療することにより、治療治験をエンターテイメントに変える"セラピアVR"を提供しています。
            VR映像により、宇宙や海や森の中に行くことによって、意識を映像に集中させます。
            また、VR映像で呼吸の長さ・タイミングをガイドし、心理療法の技法をVR化するなど、快適な治療体験を実現する工夫がなされています。
          </p>
          <br />
          <p>
            痛みや不安の大きい治療・検査では、診療科目問わず使用可能です。またリラクゼーションのためのツールとしてもご使用頂けます。
            ご興味のある方は、お気軽にお声がけください。
　　　　　　　　　　　　　　　　　　　　</p>
          <Link to="/contact/" className={styles.contact}>
            お問い合わせ
          </Link>
        </div>
        <div className={styles.company}>
          <h2>Company</h2>
          <dl>
            <dt>会社名</dt>
            <dd>株式会社xCura | xCura, Inc.</dd>
            <dt>設立日</dt>
            <dd>2021年2月2日</dd>
            <dt>所在地</dt>
            <dd className={styles.postalCode}>〒810-0041 </dd>
            <dd>福岡県福岡市中央区大名1-3-41 プリオ大名ビル2F</dd>
            <dt>代表者</dt>
            <dd>新嶋 祐一朗</dd>
          </dl>
        </div>
      </div>
    </top>
  )
}
