import React from "react"
import "modern-css-reset"
import Header from "../components/header"
import Top from "../components/top"
import Footer from "../components/footer"
import 'typeface-josefin-sans'

export default function Home() {
  return (
    <div>
      <Header />
      <Top />
      <Footer />
    </div>
  )
}
